import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../styles/error_pages.scss"
import { Link } from "gatsby"

const NotFoundPage = () => (
 <Layout>
  <SEO title="503: Server Error" />
  <section className="message msg-error">

   <h1>503</h1>
   <h2>Oops! This site is getting a tune-up.
</h2>

   <p>Why don’t you come back in a little while and see if our experts are finished tinkering. Thanks!</p>

  </section>
 </Layout>
)

export default NotFoundPage
